<script setup lang="ts">
const { isIos } = useDevice();
const title = useState("pageTitle", () => "FLIK");
const keywords = useState("pageKeywords", () => "flik, checkout, nuxt");
const route: any = useRoute();

console.log("route:", route);
const rName: any = route.name || "";
console.log("rName:", rName);
if (rName === "merchant") {
  const rParam = route.params.merchant || "";
  title.value = strCapitalized(rParam);
} else if (hasLength(rName)) title.value = rName.toUpperCase();

// onMounted(() => {

// });
</script>
<template>
  <Head>
    <Title>{{ title }}</Title>
    <Meta name="description" :content="title" />
    <Meta name="keywords" :content="keywords" />
    <Meta
      v-if="isIos"
      name="viewport"
      content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
    />
  </Head>
  <LazyHeaderMerchant class="max-w-full p-[16px]" />
  <section id="mOneColFooter" class="relative max-w-full scrollbar-hide">
    <NuxtNotifications
      classes="notify"
      :style="`position: absolute; z-index:5; left:0; top:0;`"
      width="auto"
      :duration="-5000"
      :pause-on-hover="true"
      :max="1"
    >
      <template #body="props">
        <div class="notify" :class="props.item.type">
          <div v-html="props.item.text" />
          <button class="close" @click="props.close">
            <LazyIconsClose class="h-4" />
          </button>
        </div>
      </template>
    </NuxtNotifications>
    <slot />
  </section>
  <LazyFooter class="w-full p-[16px] flex-row z-2" />
</template>
